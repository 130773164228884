import React from "react";
const opera = [
  {
    "name": "Opera PMS",
    "full_form": "Property Management System",
    "description": "Opera Property Management System (PMS) is a comprehensive software solution designed specifically for the hospitality industry to efficiently manage various operations such as reservations, guest check-ins, housekeeping, billing, and reporting."
  },
  {
    "name": "Opera ORS/OCIS",
    "full_form": "Opera Reservation System / Opera Customer Information System",
    "description": "Opera Reservation System (ORS) and Opera Customer Information System (OCIS) are modules within the Opera suite that facilitate hotel reservation management and customer relationship management, allowing hotels to streamline booking processes and enhance guest experiences."
  },
  {
    "name": "Opera Interfaces",
    "full_form": null,
    "description": "Opera Interfaces refer to the integration capabilities of the Opera PMS with other systems such as channel managers, revenue management systems, and accounting software. These interfaces enable seamless data exchange and automation of processes, optimizing hotel operations."
  },
  {
    "name": "Opera OXI",
    "full_form": "Opera Exchange Interface",
    "description": "Opera OXI (Opera Exchange Interface) is a module within the Opera suite that facilitates communication between the property management system (PMS) and various hotel systems such as point-of-sale (POS), spa management, and room key encoding systems, ensuring smooth data flow and synchronization."
  },
  {
    "name": "Opera OPI",
    "full_form": "Opera Property Interfaces",
    "description": "Opera OPI (Opera Property Interfaces) are standardized interfaces provided by Opera to enable connectivity between the Opera PMS and external systems such as central reservation systems (CRS), global distribution systems (GDS), and online travel agencies (OTA), allowing for efficient distribution of hotel inventory and rates."
  },
  {
    "name": "Opera Cloud",
    "full_form": null,
    "description": "Opera Cloud is a cloud-based property management system (PMS) offered by Oracle Hospitality. It provides hotels and hospitality businesses with a comprehensive suite of tools to manage various operations, including reservations, guest check-ins, housekeeping, billing, and reporting, all accessible from anywhere with an internet connection."
  },
  {
    "name": "Oracle POS 9700",
    "full_form": "Point of Sale 9700",
    "description": "Oracle POS 9700 is a robust point-of-sale (POS) system designed for the hospitality industry. It offers features such as order management, payment processing, inventory tracking, and reporting, enabling efficient and seamless transactions in restaurants, bars, and other foodservice establishments."
  },
  {
    "name": "Oracle POS 2700",
    "full_form": "Point of Sale 2700",
    "description": "Oracle POS 2700 is a point-of-sale (POS) system designed for the hospitality industry. It provides essential features for processing transactions, managing orders, and tracking inventory, catering to the needs of small to mid-sized foodservice businesses."
  },
  {
    "name": "Oracle POS 3700",
    "full_form": "Point of Sale 3700",
    "description": "Oracle POS 3700 is an advanced point-of-sale (POS) system designed for larger-scale operations in the hospitality industry. It offers comprehensive features for order management, payment processing, inventory control, and reporting, supporting high-volume transactions and complex business requirements."
  },
  {
    "name": "Oracle POS Symphony",
    "full_form": null,
    "description": "Oracle POS Symphony is a modern and flexible point-of-sale (POS) solution offered by Oracle Hospitality. It is designed to meet the diverse needs of food and beverage establishments, providing customizable features for order management, payment processing, customer engagement, and operational efficiency."
  },
  {
    "name": "Oracle Material Control",
    "full_form": null,
    "description": "Oracle Material Control is a component of Oracle Hospitality's suite of solutions designed to streamline inventory management and procurement processes in the hospitality industry. It helps businesses optimize inventory levels, track supplies, and manage purchasing activities to ensure efficient operations and cost control."
  }
]

function Opera()
{
return <>
     <section id="features" class="container">
      <div class="row">
      <div class="col-md-12 text-center section-header">
        <h2>Oracle Opera PMS Solutions</h2>
        <p style={{justifyContent:"space-evenly"}}>OPERA 5 property management solutions allow hoteliers to orchestrate the many facets of their business—including reservations, loyalty, and sales and catering—to elevate guest service and increase profitability. Designed for flexibility, OPERA 5 is the perfect fit for hotels of all types, from independent properties to international resorts.</p>
      </div>
      </div>
      <div className="features">
      {opera.map((o)=>{
        return <div class="feature-box-2"> 
         <a href="/#get-in-touch" style={{textDecoration:"none"}}>
              <div class="feature-box">
  
                <h2>{o.name}</h2>
                <p style={{textAlign:"justify"}}>{o.description}</p>
              </div>
            </a>
          </div>


/* <div class="col-md-4">
          <div class="feature-box-2"> 
              <div class="feature-box">
                
                <h2>{o.name}</h2>
                <p>{o.description}</p>
               
              </div>
          </div>
        </div> */
      })}
      </div>


  </section>

</>
}
export default Opera;