import React from "react";

function About()
{
    return <>
    
    <div className="about-container">
      <h1>Welcome to TechInfoAK Pvt Ltd</h1>
      <p>TechInfoAK Pvt Ltd is a leading provider of technology solutions and services, dedicated to empowering businesses with innovative tools and strategies to achieve their goals. Founded in [Year], TechInfoAK has established itself as a trusted partner for businesses across various industries, offering a wide range of products and services tailored to meet their specific needs.</p>
      
      <p>At TechInfoAK, we are committed to delivering exceptional value to our clients through innovative solutions, personalized service, and continuous support.</p>

      <h2>Our Services</h2>
      
      <p>We specialize in providing state-of-the-art Property Management System (PMS) solutions using Opera PMS, helping hotels and hospitality businesses streamline their operations and enhance guest experiences.</p>
      
      <p>Our EnviroSense solutions focus on environmental monitoring and management, offering advanced tools and technologies to ensure sustainability and compliance with regulatory standards.</p>
      
      <p>We offer comprehensive website development services, creating engaging and user-friendly websites that reflect the unique identity and objectives of our clients' businesses.</p>
      
      <p>Our digital marketing experts employ cutting-edge strategies and tools to help businesses increase their online visibility, attract more customers, and achieve higher conversion rates. We also specialize in targeted email marketing campaigns to engage with customers effectively.</p>
      
      <p>As an authorized distributor of Epson printers, we provide high-quality printing solutions for businesses of all sizes, ensuring superior performance and reliability.</p>
      
      <p>We offer a range of Microsoft products and services, including Office suites, cloud solutions, and productivity tools, to empower businesses with efficient collaboration and communication capabilities.</p>
      
      <p>Contact us today to learn how we can help your business thrive in the digital age.</p>
    </div>
    </>
}
export default About;