import React from "react";
import ContactUs from "../components/ContactUs";
import Products from "../components/Products";
import Slides from "../components/Slides";



function Home()
{
    return <>

    <Slides/>

    <Products />

    <ContactUs/>

   
    </>
}
export default Home;