import React from "react";
import { Carousel } from 'react-bootstrap';
import "./Slides.css"
const slides = [
        {
          "title": "Oracle Opera PMS Solutions",
          "tagline": "Empower Your Hospitality Business with Oracle's Premier Property Management System",
          "bg": "#FF5733"
        },
        {
          "title": "Streamlined Point of Sale Machines",
          "tagline": "Effortlessly Manage Transactions and Boost Sales Efficiency",
          "bg": " #1E335B"
        },
        {
          "title": "Monitor Your Server Room Environment",
          "tagline": "Specially designned Device for real-time Monitoring of Server Room temperature and Humidity",
          "bg": "#5733FF"
        },
        {
          "title": "Dynamic Website Development",
          "tagline": "Crafting Digital Experiences Tailored to Your Vision",
          "bg": "#483D8B"
        },
        {
          "title": "Digital Marketing Solutions",
          "tagline": "Accelerate Your Online Growth with Strategic Digital Marketing",
          "bg": "#20B2AA"
        },
        {
          "title": "Effective Email Marketing Services",
          "tagline": "Engage Audiences and Drive Conversions with Targeted Email Campaigns",
          "bg": "#CD5C5C"
        },
        {
          "title": "High-Quality Printing Solutions",
          "tagline": "Print Perfection: Delivering Exceptional Quality Every Time",
          "bg": "#3366FF"
        },
        {
          "title": "Microsoft Office Solutions",
          "tagline": "Unlocking Productivity: Enhance Workflows with Microsoft's Suite of Tools",
          "bg": "#FF3366"
        }
      ]

function Slides()
{
   return <Carousel>
    {slides.map((s)=>{
        return <Carousel.Item className="slide-item" style={{ backgroundColor: s.bg }}>
        <div className="carousel-content">
          <h3>{s.title}</h3>
          <p>{s.tagline}</p>
        </div>
      </Carousel.Item>
    })}
  
 </Carousel>

}

export default Slides