
import React from "react"
import Navigation from "./Navigation";
import Footer from "./Footer";

function Layout(props)
{
    return (
        <>
            <Navigation></Navigation>
            <div>{props.children}</div>
            {/* <Footer></Footer> */}
        </>
    )
}

export default Layout;